// source: protobuf/order_transporter_direction/order_transporter_direction.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var protobuf_validate_validate_pb = require('../../protobuf/validate/validate_pb.js');
goog.object.extend(proto, protobuf_validate_validate_pb);
var protobuf_filter_counters_pb = require('../../protobuf/filter/counters_pb.js');
goog.object.extend(proto, protobuf_filter_counters_pb);
var protobuf_filter_request_pb = require('../../protobuf/filter/request_pb.js');
goog.object.extend(proto, protobuf_filter_request_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var protobuf_model_merged_pb = require('../../protobuf/model/merged_pb.js');
goog.object.extend(proto, protobuf_model_merged_pb);
goog.exportSymbol('proto.order_transporter_direction.CreateOrderTransporterDirectionRequest', null, global);
goog.exportSymbol('proto.order_transporter_direction.GetOrderTransporterDirectionRequest', null, global);
goog.exportSymbol('proto.order_transporter_direction.GetOrderTransporterDirectionsRequest', null, global);
goog.exportSymbol('proto.order_transporter_direction.GetOrderTransporterDirectionsResponse', null, global);
goog.exportSymbol('proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse', null, global);
goog.exportSymbol('proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup', null, global);
goog.exportSymbol('proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest', null, global);
goog.exportSymbol('proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse', null, global);
goog.exportSymbol('proto.order_transporter_direction.ListOrderTransporterDirectionsRequest', null, global);
goog.exportSymbol('proto.order_transporter_direction.ListOrderTransporterDirectionsResponse', null, global);
goog.exportSymbol('proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest', null, global);
goog.exportSymbol('proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest', null, global);
goog.exportSymbol('proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest', null, global);
goog.exportSymbol('proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_transporter_direction.CreateOrderTransporterDirectionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.displayName = 'proto.order_transporter_direction.CreateOrderTransporterDirectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.displayName = 'proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest.displayName = 'proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup.repeatedFields_, null);
};
goog.inherits(proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup.displayName = 'proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.displayName = 'proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.displayName = 'proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse.displayName = 'proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_transporter_direction.ListOrderTransporterDirectionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_transporter_direction.ListOrderTransporterDirectionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_transporter_direction.ListOrderTransporterDirectionsRequest.displayName = 'proto.order_transporter_direction.ListOrderTransporterDirectionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_transporter_direction.ListOrderTransporterDirectionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order_transporter_direction.ListOrderTransporterDirectionsResponse.repeatedFields_, null);
};
goog.inherits(proto.order_transporter_direction.ListOrderTransporterDirectionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_transporter_direction.ListOrderTransporterDirectionsResponse.displayName = 'proto.order_transporter_direction.ListOrderTransporterDirectionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest.displayName = 'proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse.repeatedFields_, null);
};
goog.inherits(proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse.displayName = 'proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_transporter_direction.GetOrderTransporterDirectionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_transporter_direction.GetOrderTransporterDirectionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_transporter_direction.GetOrderTransporterDirectionRequest.displayName = 'proto.order_transporter_direction.GetOrderTransporterDirectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_transporter_direction.GetOrderTransporterDirectionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order_transporter_direction.GetOrderTransporterDirectionsRequest.repeatedFields_, null);
};
goog.inherits(proto.order_transporter_direction.GetOrderTransporterDirectionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_transporter_direction.GetOrderTransporterDirectionsRequest.displayName = 'proto.order_transporter_direction.GetOrderTransporterDirectionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_transporter_direction.GetOrderTransporterDirectionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order_transporter_direction.GetOrderTransporterDirectionsResponse.repeatedFields_, null);
};
goog.inherits(proto.order_transporter_direction.GetOrderTransporterDirectionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_transporter_direction.GetOrderTransporterDirectionsResponse.displayName = 'proto.order_transporter_direction.GetOrderTransporterDirectionsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_transporter_direction.CreateOrderTransporterDirectionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transporterDirectionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    transportationPrice: jspb.Message.getFieldWithDefault(msg, 3, ""),
    transportationCurrencyId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isFavourite: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isHired: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isTruckAvailable: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    truckAvailableDate: (f = msg.getTruckAvailableDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    truckId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    trailerId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    driverId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    driverDetails: (f = msg.getDriverDetails()) && protobuf_model_merged_pb.DriverDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_transporter_direction.CreateOrderTransporterDirectionRequest}
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_transporter_direction.CreateOrderTransporterDirectionRequest;
  return proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_transporter_direction.CreateOrderTransporterDirectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_transporter_direction.CreateOrderTransporterDirectionRequest}
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransporterDirectionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransportationPrice(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransportationCurrencyId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFavourite(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsHired(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTruckAvailable(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTruckAvailableDate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTruckId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverId(value);
      break;
    case 13:
      var value = new protobuf_model_merged_pb.DriverDetails;
      reader.readMessage(value,protobuf_model_merged_pb.DriverDetails.deserializeBinaryFromReader);
      msg.setDriverDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_transporter_direction.CreateOrderTransporterDirectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransporterDirectionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTransportationPrice();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTransportationCurrencyId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsFavourite();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsHired();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsTruckAvailable();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getTruckAvailableDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTruckId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTrailerId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDriverId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getDriverDetails();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      protobuf_model_merged_pb.DriverDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.CreateOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string transporter_direction_id = 2;
 * @return {string}
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.getTransporterDirectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.CreateOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.setTransporterDirectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string transportation_price = 3;
 * @return {string}
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.getTransportationPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.CreateOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.setTransportationPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string transportation_currency_id = 4;
 * @return {string}
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.getTransportationCurrencyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.CreateOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.setTransportationCurrencyId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool is_favourite = 5;
 * @return {boolean}
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.getIsFavourite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order_transporter_direction.CreateOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.setIsFavourite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_hired = 6;
 * @return {boolean}
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.getIsHired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order_transporter_direction.CreateOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.setIsHired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_truck_available = 7;
 * @return {boolean}
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.getIsTruckAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order_transporter_direction.CreateOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.setIsTruckAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp truck_available_date = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.getTruckAvailableDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.order_transporter_direction.CreateOrderTransporterDirectionRequest} returns this
*/
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.setTruckAvailableDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_transporter_direction.CreateOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.clearTruckAvailableDate = function() {
  return this.setTruckAvailableDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.hasTruckAvailableDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string truck_id = 10;
 * @return {string}
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.getTruckId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.CreateOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.setTruckId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string trailer_id = 11;
 * @return {string}
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.getTrailerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.CreateOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.setTrailerId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string driver_id = 12;
 * @return {string}
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.getDriverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.CreateOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.setDriverId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional model.DriverDetails driver_details = 13;
 * @return {?proto.model.DriverDetails}
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.getDriverDetails = function() {
  return /** @type{?proto.model.DriverDetails} */ (
    jspb.Message.getWrapperField(this, protobuf_model_merged_pb.DriverDetails, 13));
};


/**
 * @param {?proto.model.DriverDetails|undefined} value
 * @return {!proto.order_transporter_direction.CreateOrderTransporterDirectionRequest} returns this
*/
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.setDriverDetails = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_transporter_direction.CreateOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.clearDriverDetails = function() {
  return this.setDriverDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_transporter_direction.CreateOrderTransporterDirectionRequest.prototype.hasDriverDetails = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transporterDirectionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    transportationPrice: jspb.Message.getFieldWithDefault(msg, 3, ""),
    transportationCurrencyId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isFavourite: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isHired: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isTruckAvailable: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    truckAvailableDate: (f = msg.getTruckAvailableDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    truckId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    trailerId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    driverId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    driverDetails: (f = msg.getDriverDetails()) && protobuf_model_merged_pb.DriverDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest}
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest;
  return proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest}
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransporterDirectionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransportationPrice(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransportationCurrencyId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFavourite(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsHired(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTruckAvailable(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTruckAvailableDate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTruckId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverId(value);
      break;
    case 13:
      var value = new protobuf_model_merged_pb.DriverDetails;
      reader.readMessage(value,protobuf_model_merged_pb.DriverDetails.deserializeBinaryFromReader);
      msg.setDriverDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransporterDirectionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTransportationPrice();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTransportationCurrencyId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsFavourite();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsHired();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsTruckAvailable();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getTruckAvailableDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTruckId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTrailerId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDriverId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getDriverDetails();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      protobuf_model_merged_pb.DriverDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string transporter_direction_id = 2;
 * @return {string}
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.getTransporterDirectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.setTransporterDirectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string transportation_price = 3;
 * @return {string}
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.getTransportationPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.setTransportationPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string transportation_currency_id = 4;
 * @return {string}
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.getTransportationCurrencyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.setTransportationCurrencyId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool is_favourite = 5;
 * @return {boolean}
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.getIsFavourite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.setIsFavourite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_hired = 6;
 * @return {boolean}
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.getIsHired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.setIsHired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_truck_available = 7;
 * @return {boolean}
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.getIsTruckAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.setIsTruckAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp truck_available_date = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.getTruckAvailableDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest} returns this
*/
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.setTruckAvailableDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.clearTruckAvailableDate = function() {
  return this.setTruckAvailableDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.hasTruckAvailableDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string truck_id = 10;
 * @return {string}
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.getTruckId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.setTruckId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string trailer_id = 11;
 * @return {string}
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.getTrailerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.setTrailerId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string driver_id = 12;
 * @return {string}
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.getDriverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.setDriverId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional model.DriverDetails driver_details = 13;
 * @return {?proto.model.DriverDetails}
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.getDriverDetails = function() {
  return /** @type{?proto.model.DriverDetails} */ (
    jspb.Message.getWrapperField(this, protobuf_model_merged_pb.DriverDetails, 13));
};


/**
 * @param {?proto.model.DriverDetails|undefined} value
 * @return {!proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest} returns this
*/
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.setDriverDetails = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.clearDriverDetails = function() {
  return this.setDriverDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_transporter_direction.UpdateOrderTransporterDirectionRequest.prototype.hasDriverDetails = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transporterDirectionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    transportationPrice: jspb.Message.getFieldWithDefault(msg, 3, ""),
    transportationPriceCurrencyId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest;
  return proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransporterDirectionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransportationPrice(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransportationPriceCurrencyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransporterDirectionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTransportationPrice();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTransportationPriceCurrencyId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest} returns this
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string transporter_direction_id = 2;
 * @return {string}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest.prototype.getTransporterDirectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest} returns this
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest.prototype.setTransporterDirectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string transportation_price = 3;
 * @return {string}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest.prototype.getTransportationPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest} returns this
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest.prototype.setTransportationPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string transportation_price_currency_id = 4;
 * @return {string}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest.prototype.getTransportationPriceCurrencyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest} returns this
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionPriceInfoRequest.prototype.setTransportationPriceCurrencyId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourceCountryName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    destinationCountryName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    transporterdirectiongroupsList: jspb.Message.toObjectList(msg.getTransporterdirectiongroupsList(),
    proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup}
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup;
  return proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup}
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceCountryName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationCountryName(value);
      break;
    case 5:
      var value = new proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse;
      reader.readMessage(value,proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse.deserializeBinaryFromReader);
      msg.addTransporterdirectiongroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourceCountryName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDestinationCountryName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTransporterdirectiongroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string source_country_name = 1;
 * @return {string}
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup.prototype.getSourceCountryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup} returns this
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup.prototype.setSourceCountryName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string destination_country_name = 2;
 * @return {string}
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup.prototype.getDestinationCountryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup} returns this
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup.prototype.setDestinationCountryName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated JoinedOrderTransporterDirectionResponse transporterDirectionGroups = 5;
 * @return {!Array<!proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse>}
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup.prototype.getTransporterdirectiongroupsList = function() {
  return /** @type{!Array<!proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse, 5));
};


/**
 * @param {!Array<!proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse>} value
 * @return {!proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup} returns this
*/
proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup.prototype.setTransporterdirectiongroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse}
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup.prototype.addTransporterdirectiongroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup} returns this
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup.prototype.clearTransporterdirectiongroupsList = function() {
  return this.setTransporterdirectiongroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transporterDirectionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isFavourite: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isHired: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isTruckAvailable: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    isBestOffer: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    truckAvailableDate: (f = msg.getTruckAvailableDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    truckId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    trailerId: jspb.Message.getFieldWithDefault(msg, 16, ""),
    driverId: jspb.Message.getFieldWithDefault(msg, 17, ""),
    driverDetails: (f = msg.getDriverDetails()) && protobuf_model_merged_pb.DriverDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest}
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest;
  return proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest}
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransporterDirectionId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFavourite(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsHired(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTruckAvailable(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBestOffer(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTruckAvailableDate(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTruckId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerId(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverId(value);
      break;
    case 18:
      var value = new protobuf_model_merged_pb.DriverDetails;
      reader.readMessage(value,protobuf_model_merged_pb.DriverDetails.deserializeBinaryFromReader);
      msg.setDriverDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransporterDirectionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsFavourite();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsHired();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsTruckAvailable();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIsBestOffer();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getTruckAvailableDate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTruckId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getTrailerId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getDriverId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getDriverDetails();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      protobuf_model_merged_pb.DriverDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest} returns this
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string transporter_direction_id = 2;
 * @return {string}
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.getTransporterDirectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest} returns this
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.setTransporterDirectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_favourite = 6;
 * @return {boolean}
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.getIsFavourite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest} returns this
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.setIsFavourite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_hired = 7;
 * @return {boolean}
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.getIsHired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest} returns this
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.setIsHired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_truck_available = 8;
 * @return {boolean}
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.getIsTruckAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest} returns this
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.setIsTruckAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool is_best_offer = 9;
 * @return {boolean}
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.getIsBestOffer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest} returns this
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.setIsBestOffer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp truck_available_date = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.getTruckAvailableDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest} returns this
*/
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.setTruckAvailableDate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest} returns this
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.clearTruckAvailableDate = function() {
  return this.setTruckAvailableDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.hasTruckAvailableDate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string truck_id = 15;
 * @return {string}
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.getTruckId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest} returns this
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.setTruckId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string trailer_id = 16;
 * @return {string}
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.getTrailerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest} returns this
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.setTrailerId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string driver_id = 17;
 * @return {string}
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.getDriverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest} returns this
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.setDriverId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional model.DriverDetails driver_details = 18;
 * @return {?proto.model.DriverDetails}
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.getDriverDetails = function() {
  return /** @type{?proto.model.DriverDetails} */ (
    jspb.Message.getWrapperField(this, protobuf_model_merged_pb.DriverDetails, 18));
};


/**
 * @param {?proto.model.DriverDetails|undefined} value
 * @return {!proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest} returns this
*/
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.setDriverDetails = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest} returns this
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.clearDriverDetails = function() {
  return this.setDriverDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_transporter_direction.UpsertDealOrdersTransporterDirectionInfoRequest.prototype.hasDriverDetails = function() {
  return jspb.Message.getField(this, 18) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transporterDirectionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    transportationPrice: jspb.Message.getFieldWithDefault(msg, 3, ""),
    transportationCurrencyId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isFavourite: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isHired: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isTruckAvailable: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    truckAvailableDate: (f = msg.getTruckAvailableDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    truckId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    trailerId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    driverId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    driverDetails: (f = msg.getDriverDetails()) && protobuf_model_merged_pb.DriverDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest;
  return proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransporterDirectionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransportationPrice(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransportationCurrencyId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFavourite(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsHired(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTruckAvailable(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTruckAvailableDate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTruckId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverId(value);
      break;
    case 13:
      var value = new protobuf_model_merged_pb.DriverDetails;
      reader.readMessage(value,protobuf_model_merged_pb.DriverDetails.deserializeBinaryFromReader);
      msg.setDriverDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransporterDirectionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTransportationPrice();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTransportationCurrencyId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsFavourite();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsHired();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsTruckAvailable();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getTruckAvailableDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTruckId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTrailerId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDriverId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getDriverDetails();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      protobuf_model_merged_pb.DriverDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string transporter_direction_id = 2;
 * @return {string}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.getTransporterDirectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.setTransporterDirectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string transportation_price = 3;
 * @return {string}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.getTransportationPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.setTransportationPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string transportation_currency_id = 4;
 * @return {string}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.getTransportationCurrencyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.setTransportationCurrencyId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool is_favourite = 6;
 * @return {boolean}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.getIsFavourite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.setIsFavourite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_hired = 7;
 * @return {boolean}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.getIsHired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.setIsHired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_truck_available = 8;
 * @return {boolean}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.getIsTruckAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.setIsTruckAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp truck_available_date = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.getTruckAvailableDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest} returns this
*/
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.setTruckAvailableDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.clearTruckAvailableDate = function() {
  return this.setTruckAvailableDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.hasTruckAvailableDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string truck_id = 10;
 * @return {string}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.getTruckId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.setTruckId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string trailer_id = 11;
 * @return {string}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.getTrailerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.setTrailerId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string driver_id = 12;
 * @return {string}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.getDriverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.setDriverId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional model.DriverDetails driver_details = 13;
 * @return {?proto.model.DriverDetails}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.getDriverDetails = function() {
  return /** @type{?proto.model.DriverDetails} */ (
    jspb.Message.getWrapperField(this, protobuf_model_merged_pb.DriverDetails, 13));
};


/**
 * @param {?proto.model.DriverDetails|undefined} value
 * @return {!proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest} returns this
*/
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.setDriverDetails = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.clearDriverDetails = function() {
  return this.setDriverDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_transporter_direction.UpsertOrderTransporterDirectionRequest.prototype.hasDriverDetails = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transporterDirectionInfo: (f = msg.getTransporterDirectionInfo()) && protobuf_model_merged_pb.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.toObject(includeInstance, f),
    ordersMap: (f = msg.getOrdersMap()) ? f.toObject(includeInstance, proto.model.JoinedOrderTransporterDirectionOrderInfo.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse}
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse;
  return proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse}
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_model_merged_pb.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse;
      reader.readMessage(value,protobuf_model_merged_pb.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.deserializeBinaryFromReader);
      msg.setTransporterDirectionInfo(value);
      break;
    case 5:
      var value = msg.getOrdersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.model.JoinedOrderTransporterDirectionOrderInfo.deserializeBinaryFromReader, "", new proto.model.JoinedOrderTransporterDirectionOrderInfo());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransporterDirectionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_model_merged_pb.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse.serializeBinaryToWriter
    );
  }
  f = message.getOrdersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.model.JoinedOrderTransporterDirectionOrderInfo.serializeBinaryToWriter);
  }
};


/**
 * optional model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse transporter_direction_info = 1;
 * @return {?proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse}
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse.prototype.getTransporterDirectionInfo = function() {
  return /** @type{?proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse} */ (
    jspb.Message.getWrapperField(this, protobuf_model_merged_pb.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse, 1));
};


/**
 * @param {?proto.model.JoinedOrderTransporterDirectionDealTransporterDirectionInfoResponse|undefined} value
 * @return {!proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse} returns this
*/
proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse.prototype.setTransporterDirectionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse} returns this
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse.prototype.clearTransporterDirectionInfo = function() {
  return this.setTransporterDirectionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse.prototype.hasTransporterDirectionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * map<string, model.JoinedOrderTransporterDirectionOrderInfo> orders = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.model.JoinedOrderTransporterDirectionOrderInfo>}
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse.prototype.getOrdersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.model.JoinedOrderTransporterDirectionOrderInfo>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.model.JoinedOrderTransporterDirectionOrderInfo));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse} returns this
 */
proto.order_transporter_direction.JoinedOrderTransporterDirectionResponse.prototype.clearOrdersMap = function() {
  this.getOrdersMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_transporter_direction.ListOrderTransporterDirectionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_transporter_direction.ListOrderTransporterDirectionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_transporter_direction.ListOrderTransporterDirectionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.ListOrderTransporterDirectionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && protobuf_filter_request_pb.Request.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_transporter_direction.ListOrderTransporterDirectionsRequest}
 */
proto.order_transporter_direction.ListOrderTransporterDirectionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_transporter_direction.ListOrderTransporterDirectionsRequest;
  return proto.order_transporter_direction.ListOrderTransporterDirectionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_transporter_direction.ListOrderTransporterDirectionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_transporter_direction.ListOrderTransporterDirectionsRequest}
 */
proto.order_transporter_direction.ListOrderTransporterDirectionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_request_pb.Request;
      reader.readMessage(value,protobuf_filter_request_pb.Request.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_transporter_direction.ListOrderTransporterDirectionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_transporter_direction.ListOrderTransporterDirectionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_transporter_direction.ListOrderTransporterDirectionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.ListOrderTransporterDirectionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_request_pb.Request.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Request filter = 1;
 * @return {?proto.filter.Request}
 */
proto.order_transporter_direction.ListOrderTransporterDirectionsRequest.prototype.getFilter = function() {
  return /** @type{?proto.filter.Request} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_request_pb.Request, 1));
};


/**
 * @param {?proto.filter.Request|undefined} value
 * @return {!proto.order_transporter_direction.ListOrderTransporterDirectionsRequest} returns this
*/
proto.order_transporter_direction.ListOrderTransporterDirectionsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_transporter_direction.ListOrderTransporterDirectionsRequest} returns this
 */
proto.order_transporter_direction.ListOrderTransporterDirectionsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_transporter_direction.ListOrderTransporterDirectionsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order_transporter_direction.ListOrderTransporterDirectionsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_transporter_direction.ListOrderTransporterDirectionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order_transporter_direction.ListOrderTransporterDirectionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_transporter_direction.ListOrderTransporterDirectionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.ListOrderTransporterDirectionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    counters: (f = msg.getCounters()) && protobuf_filter_counters_pb.Counters.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    protobuf_model_merged_pb.OrderTransporterDirectionResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_transporter_direction.ListOrderTransporterDirectionsResponse}
 */
proto.order_transporter_direction.ListOrderTransporterDirectionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_transporter_direction.ListOrderTransporterDirectionsResponse;
  return proto.order_transporter_direction.ListOrderTransporterDirectionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_transporter_direction.ListOrderTransporterDirectionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_transporter_direction.ListOrderTransporterDirectionsResponse}
 */
proto.order_transporter_direction.ListOrderTransporterDirectionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_counters_pb.Counters;
      reader.readMessage(value,protobuf_filter_counters_pb.Counters.deserializeBinaryFromReader);
      msg.setCounters(value);
      break;
    case 2:
      var value = new protobuf_model_merged_pb.OrderTransporterDirectionResponse;
      reader.readMessage(value,protobuf_model_merged_pb.OrderTransporterDirectionResponse.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_transporter_direction.ListOrderTransporterDirectionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_transporter_direction.ListOrderTransporterDirectionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_transporter_direction.ListOrderTransporterDirectionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.ListOrderTransporterDirectionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCounters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_counters_pb.Counters.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      protobuf_model_merged_pb.OrderTransporterDirectionResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Counters counters = 1;
 * @return {?proto.filter.Counters}
 */
proto.order_transporter_direction.ListOrderTransporterDirectionsResponse.prototype.getCounters = function() {
  return /** @type{?proto.filter.Counters} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_counters_pb.Counters, 1));
};


/**
 * @param {?proto.filter.Counters|undefined} value
 * @return {!proto.order_transporter_direction.ListOrderTransporterDirectionsResponse} returns this
*/
proto.order_transporter_direction.ListOrderTransporterDirectionsResponse.prototype.setCounters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_transporter_direction.ListOrderTransporterDirectionsResponse} returns this
 */
proto.order_transporter_direction.ListOrderTransporterDirectionsResponse.prototype.clearCounters = function() {
  return this.setCounters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_transporter_direction.ListOrderTransporterDirectionsResponse.prototype.hasCounters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated model.OrderTransporterDirectionResponse items = 2;
 * @return {!Array<!proto.model.OrderTransporterDirectionResponse>}
 */
proto.order_transporter_direction.ListOrderTransporterDirectionsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.model.OrderTransporterDirectionResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, protobuf_model_merged_pb.OrderTransporterDirectionResponse, 2));
};


/**
 * @param {!Array<!proto.model.OrderTransporterDirectionResponse>} value
 * @return {!proto.order_transporter_direction.ListOrderTransporterDirectionsResponse} returns this
*/
proto.order_transporter_direction.ListOrderTransporterDirectionsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.model.OrderTransporterDirectionResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.OrderTransporterDirectionResponse}
 */
proto.order_transporter_direction.ListOrderTransporterDirectionsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.model.OrderTransporterDirectionResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order_transporter_direction.ListOrderTransporterDirectionsResponse} returns this
 */
proto.order_transporter_direction.ListOrderTransporterDirectionsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dealId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    search: jspb.Message.getFieldWithDefault(msg, 3, ""),
    filter: (f = msg.getFilter()) && protobuf_filter_request_pb.Request.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest}
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest;
  return proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest}
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDealId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 4:
      var value = new protobuf_filter_request_pb.Request;
      reader.readMessage(value,protobuf_filter_request_pb.Request.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDealId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      protobuf_filter_request_pb.Request.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deal_id = 1;
 * @return {string}
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest.prototype.getDealId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest} returns this
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest.prototype.setDealId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string order_id = 2;
 * @return {string}
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest} returns this
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string search = 3;
 * @return {string}
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest} returns this
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional filter.Request filter = 4;
 * @return {?proto.filter.Request}
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest.prototype.getFilter = function() {
  return /** @type{?proto.filter.Request} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_request_pb.Request, 4));
};


/**
 * @param {?proto.filter.Request|undefined} value
 * @return {!proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest} returns this
*/
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest} returns this
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    counters: (f = msg.getCounters()) && protobuf_filter_counters_pb.Counters.toObject(includeInstance, f),
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    protobuf_model_merged_pb.OrderResponse.toObject, includeInstance),
    sourcedestinationcountrygroupsList: jspb.Message.toObjectList(msg.getSourcedestinationcountrygroupsList(),
    proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse}
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse;
  return proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse}
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new protobuf_filter_counters_pb.Counters;
      reader.readMessage(value,protobuf_filter_counters_pb.Counters.deserializeBinaryFromReader);
      msg.setCounters(value);
      break;
    case 4:
      var value = new protobuf_model_merged_pb.OrderResponse;
      reader.readMessage(value,protobuf_model_merged_pb.OrderResponse.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    case 5:
      var value = new proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup;
      reader.readMessage(value,proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup.deserializeBinaryFromReader);
      msg.addSourcedestinationcountrygroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCounters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      protobuf_filter_counters_pb.Counters.serializeBinaryToWriter
    );
  }
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      protobuf_model_merged_pb.OrderResponse.serializeBinaryToWriter
    );
  }
  f = message.getSourcedestinationcountrygroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional filter.Counters counters = 1;
 * @return {?proto.filter.Counters}
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse.prototype.getCounters = function() {
  return /** @type{?proto.filter.Counters} */ (
    jspb.Message.getWrapperField(this, protobuf_filter_counters_pb.Counters, 1));
};


/**
 * @param {?proto.filter.Counters|undefined} value
 * @return {!proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse} returns this
*/
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse.prototype.setCounters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse} returns this
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse.prototype.clearCounters = function() {
  return this.setCounters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse.prototype.hasCounters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated model.OrderResponse orders = 4;
 * @return {!Array<!proto.model.OrderResponse>}
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.model.OrderResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, protobuf_model_merged_pb.OrderResponse, 4));
};


/**
 * @param {!Array<!proto.model.OrderResponse>} value
 * @return {!proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse} returns this
*/
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.model.OrderResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.OrderResponse}
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.model.OrderResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse} returns this
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};


/**
 * repeated JoinedOrderTransporterDirectionSourceDestinationCountryGroup sourceDestinationCountryGroups = 5;
 * @return {!Array<!proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup>}
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse.prototype.getSourcedestinationcountrygroupsList = function() {
  return /** @type{!Array<!proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup, 5));
};


/**
 * @param {!Array<!proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup>} value
 * @return {!proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse} returns this
*/
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse.prototype.setSourcedestinationcountrygroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup}
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse.prototype.addSourcedestinationcountrygroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.order_transporter_direction.JoinedOrderTransporterDirectionSourceDestinationCountryGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse} returns this
 */
proto.order_transporter_direction.ListJoinedOrderTransporterDirectionsResponse.prototype.clearSourcedestinationcountrygroupsList = function() {
  return this.setSourcedestinationcountrygroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_transporter_direction.GetOrderTransporterDirectionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_transporter_direction.GetOrderTransporterDirectionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_transporter_direction.GetOrderTransporterDirectionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.GetOrderTransporterDirectionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transporterDirectionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_transporter_direction.GetOrderTransporterDirectionRequest}
 */
proto.order_transporter_direction.GetOrderTransporterDirectionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_transporter_direction.GetOrderTransporterDirectionRequest;
  return proto.order_transporter_direction.GetOrderTransporterDirectionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_transporter_direction.GetOrderTransporterDirectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_transporter_direction.GetOrderTransporterDirectionRequest}
 */
proto.order_transporter_direction.GetOrderTransporterDirectionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransporterDirectionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_transporter_direction.GetOrderTransporterDirectionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_transporter_direction.GetOrderTransporterDirectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_transporter_direction.GetOrderTransporterDirectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.GetOrderTransporterDirectionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransporterDirectionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.order_transporter_direction.GetOrderTransporterDirectionRequest.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.GetOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.GetOrderTransporterDirectionRequest.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string transporter_direction_id = 2;
 * @return {string}
 */
proto.order_transporter_direction.GetOrderTransporterDirectionRequest.prototype.getTransporterDirectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_transporter_direction.GetOrderTransporterDirectionRequest} returns this
 */
proto.order_transporter_direction.GetOrderTransporterDirectionRequest.prototype.setTransporterDirectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order_transporter_direction.GetOrderTransporterDirectionsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_transporter_direction.GetOrderTransporterDirectionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_transporter_direction.GetOrderTransporterDirectionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_transporter_direction.GetOrderTransporterDirectionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.GetOrderTransporterDirectionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: jspb.Message.toObjectList(msg.getIdsList(),
    proto.order_transporter_direction.GetOrderTransporterDirectionRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_transporter_direction.GetOrderTransporterDirectionsRequest}
 */
proto.order_transporter_direction.GetOrderTransporterDirectionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_transporter_direction.GetOrderTransporterDirectionsRequest;
  return proto.order_transporter_direction.GetOrderTransporterDirectionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_transporter_direction.GetOrderTransporterDirectionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_transporter_direction.GetOrderTransporterDirectionsRequest}
 */
proto.order_transporter_direction.GetOrderTransporterDirectionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.order_transporter_direction.GetOrderTransporterDirectionRequest;
      reader.readMessage(value,proto.order_transporter_direction.GetOrderTransporterDirectionRequest.deserializeBinaryFromReader);
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_transporter_direction.GetOrderTransporterDirectionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_transporter_direction.GetOrderTransporterDirectionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_transporter_direction.GetOrderTransporterDirectionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.GetOrderTransporterDirectionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.order_transporter_direction.GetOrderTransporterDirectionRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GetOrderTransporterDirectionRequest ids = 1;
 * @return {!Array<!proto.order_transporter_direction.GetOrderTransporterDirectionRequest>}
 */
proto.order_transporter_direction.GetOrderTransporterDirectionsRequest.prototype.getIdsList = function() {
  return /** @type{!Array<!proto.order_transporter_direction.GetOrderTransporterDirectionRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.order_transporter_direction.GetOrderTransporterDirectionRequest, 1));
};


/**
 * @param {!Array<!proto.order_transporter_direction.GetOrderTransporterDirectionRequest>} value
 * @return {!proto.order_transporter_direction.GetOrderTransporterDirectionsRequest} returns this
*/
proto.order_transporter_direction.GetOrderTransporterDirectionsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.order_transporter_direction.GetOrderTransporterDirectionRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.order_transporter_direction.GetOrderTransporterDirectionRequest}
 */
proto.order_transporter_direction.GetOrderTransporterDirectionsRequest.prototype.addIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.order_transporter_direction.GetOrderTransporterDirectionRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order_transporter_direction.GetOrderTransporterDirectionsRequest} returns this
 */
proto.order_transporter_direction.GetOrderTransporterDirectionsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order_transporter_direction.GetOrderTransporterDirectionsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_transporter_direction.GetOrderTransporterDirectionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order_transporter_direction.GetOrderTransporterDirectionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_transporter_direction.GetOrderTransporterDirectionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.GetOrderTransporterDirectionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    protobuf_model_merged_pb.OrderTransporterDirectionResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_transporter_direction.GetOrderTransporterDirectionsResponse}
 */
proto.order_transporter_direction.GetOrderTransporterDirectionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_transporter_direction.GetOrderTransporterDirectionsResponse;
  return proto.order_transporter_direction.GetOrderTransporterDirectionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_transporter_direction.GetOrderTransporterDirectionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_transporter_direction.GetOrderTransporterDirectionsResponse}
 */
proto.order_transporter_direction.GetOrderTransporterDirectionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new protobuf_model_merged_pb.OrderTransporterDirectionResponse;
      reader.readMessage(value,protobuf_model_merged_pb.OrderTransporterDirectionResponse.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_transporter_direction.GetOrderTransporterDirectionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_transporter_direction.GetOrderTransporterDirectionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_transporter_direction.GetOrderTransporterDirectionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_transporter_direction.GetOrderTransporterDirectionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      protobuf_model_merged_pb.OrderTransporterDirectionResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated model.OrderTransporterDirectionResponse items = 2;
 * @return {!Array<!proto.model.OrderTransporterDirectionResponse>}
 */
proto.order_transporter_direction.GetOrderTransporterDirectionsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.model.OrderTransporterDirectionResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, protobuf_model_merged_pb.OrderTransporterDirectionResponse, 2));
};


/**
 * @param {!Array<!proto.model.OrderTransporterDirectionResponse>} value
 * @return {!proto.order_transporter_direction.GetOrderTransporterDirectionsResponse} returns this
*/
proto.order_transporter_direction.GetOrderTransporterDirectionsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.model.OrderTransporterDirectionResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.OrderTransporterDirectionResponse}
 */
proto.order_transporter_direction.GetOrderTransporterDirectionsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.model.OrderTransporterDirectionResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order_transporter_direction.GetOrderTransporterDirectionsResponse} returns this
 */
proto.order_transporter_direction.GetOrderTransporterDirectionsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


goog.object.extend(exports, proto.order_transporter_direction);
